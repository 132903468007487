import {
  createMarker,
  createText,
  getMarkerTemplate,
} from './utils';
import CropImage from './crop-image';
import googleKey from '../config/config';

export default class MindPalace {
  constructor() {
    this.$scene = document.querySelector('a-scene.mindpalace');
    this.$authoringOverlay = document.querySelector('.js-overlay-authoring');
    this.$markerAmount = document.querySelector('.js-marker-amount');

    this.$contentSetupContent = document.querySelector('.js-section-content-setup-contents');
    this.$shareTargetContent = document.querySelector('.js-section-content-setup-share-target');

    this.$cropImagePreview = document.querySelector('.js-image-crop-preview');
    this.$cropImageOverlay = document.querySelector('.js-overlay-crop-image');

    this.$buttonReset = document.querySelector('.js-reset');
    this.$buttonMarkerAmount = document.querySelector('.js-button-marker-amount');
    this.$buttonMarkerSetup = document.querySelector('.js-button-marker-setup');
    this.$buttonContentSetup = document.querySelector('.js-button-content-setup');
    this.$buttonContentSetupSaveOnly = document.querySelector('.js-button-content-setup-save-only');
    this.$buttonCropUpload = document.querySelector('.js-button-crop-upload');
    this.$buttonCropCancel = document.querySelector('.js-button-crop-cancel');

    this.$buttons = document.querySelectorAll('.js-button');

    if (localStorage.getItem('markers')) {
      this.markers = JSON.parse(localStorage.getItem('markers'));
      this.markerAmount = this.markers.length;
    } else {
      this.markers = [];
      this.markerAmount = 5;
    }

    this.numberOfTextAreas = document.querySelectorAll('.authoring textarea').length;

    this.addEventListener();

    if (this.$markerAmount) {
      this.$markerAmount.textContent = this.markerAmount;
    }

    if (this.$buttonMarkerSetup) {
      this.renderMarkerSetup();
    }

    if (this.$contentSetupContent) {
      this.renderContentSetup();
    }

    if (this.$shareTargetContent) {
      this.renderContentSetupForShareTarget();
    }

    if (this.$scene) {
      window.setTimeout(() => this.renderAframeTexts(), 2000);
    }
  }

  addEventListener() {
    if (this.$buttonMarkerAmount) {
      document.querySelector('.js-marker-amount-decrease').addEventListener('click', (e) => {
        this.markerAmount = Math.max(1, this.markerAmount - 1);
        this.$markerAmount.textContent = this.markerAmount;
        e.preventDefault();
      });

      document.querySelector('.js-marker-amount-increase').addEventListener('click', (e) => {
        this.markerAmount = Math.min(20, this.markerAmount + 1);
        this.$markerAmount.textContent = this.markerAmount;
        e.preventDefault();
      });

      this.$buttonMarkerAmount.addEventListener('click', () => {
        if (this.markerAmount < this.markers.length) {
          this.markers = this.markers.slice(0, this.markerAmount);
        } else if (this.markerAmount > this.markers.length) {
          const diff = this.markerAmount - this.markers.length;
          for (let i = 0; i < diff; i += 1) {
            this.markers.push(getMarkerTemplate());
          }
        }
        localStorage.setItem('markers', JSON.stringify(this.markers));
        window.location = this.$buttonMarkerAmount.getAttribute('href');
        return false;
      });
    }

    if (this.$buttonMarkerSetup) {
      this.$buttonMarkerSetup.addEventListener('click', () => {
        document.querySelectorAll('.js-marker-name').forEach((markerNameField) => {
          const index = parseInt(markerNameField.getAttribute('data-marker-index'), 10);
          this.markers[index].name = markerNameField.value;
        });
        localStorage.setItem('markers', JSON.stringify(this.markers));
        window.location = this.$buttonMarkerSetup.getAttribute('href');
        return false;
      });
    }

    if (this.$buttonContentSetup) {
      this.$buttonContentSetup.addEventListener('click', () => {
        document.querySelectorAll('.js-marker-text').forEach((markerTextArea) => {
          const index = parseInt(markerTextArea.getAttribute('data-marker-index'), 10);
          this.markers[index].text = markerTextArea.value;
        });
        localStorage.setItem('markers', JSON.stringify(this.markers));
        window.location = this.$buttonContentSetup.getAttribute('href');
        return false;
      });
    }

    if (this.$buttonContentSetupSaveOnly) {
      this.$buttonContentSetupSaveOnly.addEventListener('click', () => {
        document.querySelectorAll('.js-marker-text').forEach((markerTextArea) => {
          const index = parseInt(markerTextArea.getAttribute('data-marker-index'), 10);
          this.markers[index].text = markerTextArea.value;
        });
        localStorage.setItem('markers', JSON.stringify(this.markers));
        return false;
      });
    }

    if (this.$buttonReset) {
      this.$buttonReset.addEventListener('click', () => {
        localStorage.removeItem('markers');
        localStorage.removeItem('drawing-board-drawingboard');
        window.location.href = '/';
      });
    }
  }

  renderAframeTexts() {
    this.markers.forEach((marker, index) => {
      const aMarker = createMarker(index + 1);
      const text = createText(marker.text.replace(';', '') || 'no content');
      aMarker.appendChild(text);
      this.$scene.appendChild(aMarker);
    });
  }

  navigateToSection(sectionSelector) {
    document.querySelectorAll('.section').forEach(section => section.classList.add('section--hidden'));
    document.querySelector(sectionSelector).classList.remove('section--hidden');
  }

  renderMarkerSetup() {
    let markup = '';
    this.markers.forEach((marker, index) => {
      markup += `
        <div class="box">
          <span class="circle mr-2">${index + 1}</span>
          <input type="text" class="js-marker-name" data-marker-index="${index}" placeholder="Name of loci ${index + 1}" value="${marker.name}">
        </div>
      `;
    });

    document.querySelector('.js-section-marker-setup-markers').innerHTML = markup;
  }

  renderContentSetup() {
    let markup = '';
    this.markers.forEach((marker, index) => {
      markup += `
        <div class="box">
          <h2><span class="circle">${index + 1}</span> ${marker.name}</h2>
          <textarea class="js-marker-text js-marker-text-${index}" data-marker-index="${index}">${marker.text}</textarea><br>
          <input type="file" class="file-input js-ocr-file js-ocr-file-${index}" id="ocr-file-${index}" data-marker-index="${index}">
          <label for="ocr-file-${index}" class="button js-ocr-button-${index}">Scan Text</label>
        </div>
      `;
    });

    this.$contentSetupContent.innerHTML = markup;
    document.querySelectorAll('.js-ocr-file').forEach(fileInput => fileInput.addEventListener('change', (e) => {
      const index = e.currentTarget.getAttribute('data-marker-index');
      document.querySelector(`.js-ocr-button-${index}`).textContent = 'Waiting...';
      this.showImagePreview(index);
    }));
  }

  renderContentSetupForShareTarget() {
    let markup = '';
    this.markers.forEach((marker, index) => {
      markup += `
        <div class="box">
          <h2><span class="circle">${index + 1}</span> ${marker.name}</h2>
          <textarea class="js-marker-text js-marker-text-${index}" data-marker-index="${index}">${marker.text}</textarea><br>
          <a href="#" class="button js-button-share-target-place-here" data-marker-index="${index}">Insert Here</a>
        </div>
      `;
    });

    this.$shareTargetContent.innerHTML = markup;

    const sharedText = (new URL(document.location)).searchParams.get('text');

    document.querySelectorAll('.js-button-share-target-place-here').forEach(button => button.addEventListener('click', (e) => {
      const index = e.currentTarget.getAttribute('data-marker-index');
      document.querySelector(`.js-marker-text-${index}`).value = sharedText;
      e.preventDefault();
    }));
  }

  showImagePreview(index) {
    const file = document.querySelector(`.js-ocr-file-${index}`).files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.$cropImagePreview.src = reader.result;
      const cropper = new CropImage(this.$cropImagePreview);
      this.$buttonCropUpload.addEventListener('click', () => {
        const base64 = cropper.getCroppedBase64();
        this.doOCR(base64, index);
        this.$cropImageOverlay.classList.add('hidden');
        cropper.destroy();
      });
      this.$buttonCropCancel.addEventListener('click', () => {
        this.$cropImageOverlay.classList.add('hidden');
        document.querySelector(`.js-ocr-button-${index}`).textContent = 'Scan Text';
        cropper.destroy();
      });
    };
    reader.readAsDataURL(file);
    this.$cropImageOverlay.classList.remove('hidden');
  }

  doOCR(fileBase64, index) {
    const base64 = fileBase64.split(',')[1];
    const url = `https://vision.googleapis.com/v1/images:annotate?key=${googleKey}`;
    const request = {
      requests: [
        {
          image: {
            content: base64,
          },
          features: [
            {
              type: 'TEXT_DETECTION',
            },
          ],
        },
      ],
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(request),
    })
    .then(response => response.json())
    .then((data) => {
      console.log(data);
      if (data.responses.length > 0 && data.responses[0].fullTextAnnotation) {
        document.querySelector(`.js-marker-text-${index}`).value = data.responses[0].fullTextAnnotation.text.replace(/(\r\n|\n|\r)/gm, ' ');
      } else {
        document.querySelector(`.js-marker-text-${index}`).value = 'no text found';
      }
      document.querySelector(`.js-ocr-button-${index}`).textContent = 'Rescan Text';
    });
  }
}
