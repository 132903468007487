import Cropper from 'cropperjs';

export default class CropImage {
  constructor(imageElement) {
    this.cropper = new Cropper(imageElement, {
      zoomable: false,
      initialAspectRatio: 16 / 9,
      dragMode: 'none',
    });
  }

  getCroppedBase64() {
    return this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
  }

  destroy() {
    this.cropper.destroy();
  }
}
