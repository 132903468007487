export function setAttributes(el, attrs) {
  for (const key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

export function createMarker(value) {
  const marker = document.createElement('a-marker');
  setAttributes(marker, {
    type: 'barcode',
    value,
  });
  return marker;
}

export function createText(text) {
  const planeObject = document.createElement('a-plane');
  setAttributes(planeObject, {
    position: '0 0 0',
    rotation: '-45 0 0',
    width: 3,
    height: 2,
    color: '#FFF',
    text: `value: ${text}; shader: msdf; font: https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/lato/Lato-Bold.json; width: 2; color:#000; zOffset: 0.5;`,
  });
  return planeObject;
}

export function getMarkerTemplate() {
  return {
    name: '',
    text: '',
    position: {
      x: null,
      y: null,
    },
  };
}
