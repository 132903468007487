import '../vendor/drawingboard';
import { getMarkerTemplate } from './utils';

export default class Drawingboard {
  constructor() {
    this.$placementOverlay = document.querySelector('.js-drawingboard-placement-overlay');
    this.$buttonSwitchModes = document.querySelector('.js-drawingboard-switch-modes');

    if (this.$placementOverlay) {
      if (localStorage.getItem('markers')) {
        this.markers = JSON.parse(localStorage.getItem('markers'));
      } else {
        this.markers = [];
      }

      this.initDrawingboard();
      this.addPlacementListener();
      this.renderMarkers();
    }
  }

  addPlacementListener() {
    this.$placementOverlay.addEventListener('click', (e) => {
      this.getDrawingboardSizes();
      const relativeX = (e.pageX - this.offset.left) / this.overlayWidth;
      const relativeY = (e.pageY - (this.offset.top + this.toolbarHeight)) / (this.overlayHeight - this.toolbarHeight);

      const position = {
        x: relativeX,
        y: Math.max(relativeY, 0),
      };

      this.placeMarker(position);
    });

    this.$buttonSwitchModes.addEventListener('click', (e) => {
      if (this.$placementOverlay.classList.contains('hidden')) {
        this.$placementOverlay.classList.remove('hidden');
        this.$buttonSwitchModes.textContent = 'Back to drawing mode';
      } else {
        this.$placementOverlay.classList.add('hidden');
        this.$buttonSwitchModes.textContent = 'Enter loci placement mode';
      }
      e.preventDefault();
    });
  }

  getDrawingboardSizes() {
    this.offset = $(this.$placementOverlay).offset();
    this.overlayHeight = $(this.$placementOverlay).height();
    this.overlayWidth = $(this.$placementOverlay).width();
    this.toolbarHeight = this.overlayHeight - $('.drawing-board-canvas-wrapper').height();
  }

  placeMarker(position) {
    const index = this.markers.length;
    const marker = getMarkerTemplate();
    marker.position = position;
    const name = prompt('Enter the name of the loci you want to place here.');
    if (name !== null) {
      marker.name = name
      this.markers.push(marker);
      localStorage.setItem('markers', JSON.stringify(this.markers));
      this.renderMarker(marker, index);
      console.log('added marker', marker);
    }
  }

  renderMarkers() {
    for (let i = 0; i < this.markers.length; i += 1) {
      this.renderMarker(this.markers[i], i);
    }
  }

  renderMarker(marker, index) {
    console.log('render marker', marker);
    if (marker.position === undefined || marker.position.x === null || marker.position.y === null) {
      return;
    }
    const markerNode = document.createElement('div');
    markerNode.setAttribute('data-index', index);
    markerNode.setAttribute('title', marker.name);
    markerNode.classList.add('drawingboard__marker');
    markerNode.style = `bottom: calc(${100 - (marker.position.y * 100)}% - ${this.toolbarHeight - 7}px); left: calc(${marker.position.x * 100}% - 7px);`;
    document.querySelector('#drawingboard').appendChild(markerNode);
    const that = this;
    markerNode.addEventListener('click', function() {
      if (confirm(`Delete loci "${this.getAttribute('title')}?"`)) {
        const deleteIndex = this.getAttribute('data-index');
        that.removeMarker(deleteIndex);
      }
    });
  }

  removeMarker(index) {
    const $node = $(`.drawingboard__marker[data-index=${index}]`);
    $node.remove();
    this.markers.splice(index, 1);
    localStorage.setItem('markers', JSON.stringify(this.markers));
  }

  initDrawingboard() {
    this.board = new DrawingBoard.Board('drawingboard', {
      background: '#FFF',
      color: '#000',
      size: 3,
      controls: [
        {
          Size: {
            type: 'dropdown',
          },
        },
        {
          Navigation: {
            back: true,
            forward: true,
          },
        },
        {
          DrawingMode: { filler: false },
        },
      ],
      webStorage: 'local',
    });
    this.getDrawingboardSizes();
  }
}
