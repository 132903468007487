export default class RotateDevice {
  constructor() {
    this.$overlay = document.querySelector('.js-overlay-rotate-device');

    if (this.$overlay) {
      this.listen();
    }
  }

  listen() {
    window.addEventListener('resize', () => {
      if (window.innerWidth > window.innerHeight) {
        this.$overlay.classList.add('hidden');
      } else {
        this.$overlay.classList.remove('hidden');
      }
    });
  }
}
